import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'web-location',
  templateUrl: './web-location.component.html',
  styleUrls: ['./web-location.component.css']
})
export class WebLocationComponent implements OnInit {

htmlContent:any
	itemId:any


  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
	  this.route.paramMap.subscribe(params=>{
		  this.itemId=params.get('itemId');
		  console.log("data is :"+this.itemId);
	  this.htmlContent = localStorage.getItem('htmlResponse');
	  });
  }

}
