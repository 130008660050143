import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Import all the components and services
import { UrlConsumerService } from './oauth/url-consumer.service';
import { AuthGuardService } from './oauth/auth-guard.service';
import { HomeComponent } from './home/home.component';
import { AccountRequestComponent } from './home/account-request/account-request.component';
import { TeamCreateComponent } from './home/team-create/team-create.component';
import { WorkInstructionsComponent } from './home/work-instructions/work-instructions.component';
import { FaqComponent } from './help/faq/faq.component';
import { ApdmHelpComponent } from './help/apdm-help/apdm-help.component';
import { UnderConstructionComponent } from './error-page/under-construction/under-construction.component';
import { AccStatusComponent } from './home/acc-status/acc-status.component';
import { TeamModifyComponent } from './home/team-modify/team-modify.component';
import { MainPageComponent } from './home/main-page/main-page.component';
import { LoginComponent } from './oauth/login.component';
import { EmailDownloadComponent } from './EmailDownload/email-download.component';
import { UploadFromUrlComponent } from './upload-from-url/upload-from-url.component';
import { ViewPropDownStreamComponentComponent } from './view-prop-down-stream-component/view-prop-down-stream-component.component';
import { ViewParentComponentComponent } from './view-parent-component/view-parent-component.component';
import { WebLocationComponent } from './web-location/web-location.component';
import { WeblocationFileDownloadComponent } from './weblocation-file-download/weblocation-file-download.component';
import { FileDownloadOnViewComponent } from './file-download-on-view/file-download-on-view.component';
import { AppComponent } from './app.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { AdminComponent } from './admin/admin.component';
import { CreateUserComponent } from './admin/create-user/create-user.component';
import { ModifyUserComponent } from './admin/modify-user/modify-user.component';
import { CreateTeamComponent } from './admin/create-team/create-team.component';
import { ModifyTeamComponent } from './admin/modify-team/modify-team.component';
import { ListUserComponent } from './admin/list-user/list-user.component';
import { TransferUserComponent } from './admin/transfer-user/transfer-user.component';
import { ListTeamComponent } from './admin/list-team/list-team.component';
import { UserNotifyComponent } from './admin/user-notify/user-notify.component';
import { AdminLoginComponent } from './admin/admin-login/admin-login.component';
import { ListmembershipTeamComponent } from './admin/listmembership-team/listmembership-team.component';
import { OstMetricsComponent } from './admin/ost-metrics/ost-metrics.component';
import { ModifyProgramComponent } from './admin/modify-program/modify-program.component';
import { CreateItemComponent } from './admin/create-item/create-item.component';
import { ListItemComponent } from './admin/list-item/list-item.component';
import { AccountAuthComponentComponent } from './admin/account-auth-component/account-auth-component.component';
import { InitialAfrComponent } from './admin/initial-afr/initial-afr.component';
import { AfrInitalProcessComponent } from './admin/afr-inital-process/afr-inital-process.component';
import { AfrReportComponent } from './admin/afr-report/afr-report.component';
import { AfrRetentioncodeComponent } from './admin/afr-retentioncode/afr-retentioncode.component';
import { AfrFordlevelComponent } from './admin/afr-fordlevel/afr-fordlevel.component';
import { RetentionComponent } from './admin/retention/retention.component';
import { AfrHelpComponent } from './admin/afr-help/afr-help.component';
import { AfrHomeComponent } from './admin/afr-home/afr-home.component';
import { DeleteFilesComponent } from './admin/delete-files/delete-files.component';
import { CreateAclComponent } from './admin/create-acl/create-acl.component';
import { ApplicationInfoComponent } from './admin/application-info/application-info.component';
import { AfrReportsComponent } from './admin/afr-reports/afr-reports.component';
import { AppReauthorizationComponent } from './admin/app-reauthorization/app-reauthorization.component';
import { ErrorMessageComponent } from './admin/error-message/error-message.component';

export const routes: Routes = [
	{
		path: 'access_token',
		component: AppComponent,
		canActivate: [AuthGuardService],
	},
	{
		path: '',
		redirectTo: '/home/main-page',
		pathMatch: 'full',
	},
	{
		path: 'admin-portal',
		component: AdminComponent,
		children: [
			{
				path: '',
				redirectTo: 'login',
				pathMatch: 'full'
			},
			{
				path: 'login',
				component: AdminLoginComponent
			},
			{
				path: 'create-user',
				component: CreateUserComponent,
				canActivate: [AuthGuardService]
			},
			{
				path: 'modifyUser',
				component: ModifyUserComponent,
				canActivate: [AuthGuardService]
			},
			{
				path: 'team-create',
				component: CreateTeamComponent,
				canActivate: [AuthGuardService]
			},
			{
				path: 'team-modify',
				component: ModifyTeamComponent,
				canActivate: [AuthGuardService]
			},
			{
				path: 'listUser',
				component: ListUserComponent,
				canActivate: [AuthGuardService]
			},
			{
				path: 'transferUserFile',
				component: TransferUserComponent,
				canActivate: [AuthGuardService]
			},
			{
				path: 'list-team',
				component: ListTeamComponent,
				canActivate: [AuthGuardService]
			},
			{
				path: 'notifyUser',
				component: UserNotifyComponent,
				canActivate: [AuthGuardService]
			},
			{
				path: 'listmember-team',
				component: ListmembershipTeamComponent,
				canActivate: [AuthGuardService]
			},
			{
				path: 'ost-metrics',
				component: OstMetricsComponent,
				canActivate: [AuthGuardService]
			},
			{
				path: 'modifyProgram/:type',
				component: ModifyProgramComponent,
				canActivate: [AuthGuardService]
			},
			{
				path: 'createItem',
				component: CreateItemComponent,
				canActivate: [AuthGuardService]
			},
			{
				path: 'listItem/:type',
				component: ListItemComponent,
				canActivate: [AuthGuardService]
			},
			{
				path: 'modifyItem/:type',
				component: ListItemComponent,
				canActivate: [AuthGuardService]
			},
			{
				path: 'accountAuth/:authYearStr/:authCompletionStr/:authReminderNoStr/:approverCdsidsStr/:teamIdsStr',
				component: AccountAuthComponentComponent,
			},
			{
				path: 'AnnualFileReview',
				component: InitialAfrComponent,
				canActivate: [AuthGuardService],
				children: [
					{
						path: 'runAnnualFileReview',
						component: AfrInitalProcessComponent,
						canActivate: [AuthGuardService]
					},
					{
						path: 'reportMain',
						component: AfrReportComponent,
						canActivate: [AuthGuardService]
					},
					{
						path: 'retentioncodelevel/:cdsid/:year',
						component: AfrRetentioncodeComponent,
						canActivate: [AuthGuardService]
					},
					{
						path: 'retentionfolderlevel/:cdsid/:year',
						component: AfrFordlevelComponent,
						canActivate: [AuthGuardService]
					},
					{
						path: 'retention',
						component: RetentionComponent,
						canActivate: [AuthGuardService]
					},
					{
						path: 'afrHelp',
						component: AfrHelpComponent,
						canActivate: [AuthGuardService]
					},
					{
						path: 'afrhome/:cdsid/:year',
						component: AfrHomeComponent,
						data: { afrlink: true },
						canActivate: [AuthGuardService]
					},
					{
						path: 'menu/afrhome/:cdsid/:year',
						component: AfrHomeComponent,
						canActivate: [AuthGuardService]
					},
					{
						path: 'showDeletedLog/:cdsid/:year',
						component: DeleteFilesComponent,
						data: { afrlink: true },
						canActivate: [AuthGuardService]
					},
					{
						path: 'menu/showDeletedLog/:cdsid/:year',
						component: DeleteFilesComponent,
						canActivate: [AuthGuardService]
					},
				]
			},
			{
				path: 'createAcl',
				component: CreateAclComponent,
				canActivate: [AuthGuardService]
			},
			{
				path: 'applicationInfo',
				component: ApplicationInfoComponent,
				canActivate: [AuthGuardService]
			},
			{
				path: 'afrReports',
				component: AfrReportsComponent,
				canActivate: [AuthGuardService]
			},
			{
				path: 'appreauthorization',
				component: AppReauthorizationComponent,
				canActivate: [AuthGuardService]
			},
			{
				path: 'error',
				component: ErrorMessageComponent,
				canActivate: [AuthGuardService]
			},
		],
		canActivate: [AuthGuardService]
	},
	{
		path: 'login',
		component: LoginComponent,
	},
	{
		path: 'admin',
		loadChildren: 'app/admin/admin.module#AdminModule',
		canActivate: [AuthGuardService]
	},
	{
		path: 'webclient',
		loadChildren: 'app/web-client/webclientmodule.module#WebclientmoduleModule',
		canActivate: [AuthGuardService]
	},
	{
		path: 'upload/:parentItemId',
		component: UploadFromUrlComponent,
		canActivate: [AuthGuardService],
	},
	{
		path: 'under-Construction',
		component: UnderConstructionComponent,
		canActivate: [AuthGuardService],
	},
	{
		path: 'web-location/:itemId',
		component: WebLocationComponent,
		canActivate: [AuthGuardService],
	},
	{
		path: 'item/:itemId',
		component: ViewPropDownStreamComponentComponent,
		canActivate: [AuthGuardService],
	},
	{
		path: 'apdm/v4.0/by-id/item/:data',
		component: ViewParentComponentComponent,
		canActivate: [AuthGuardService],
	},
	{
		path: 'parent/:data',
		component: ViewParentComponentComponent,
		canActivate: [AuthGuardService],
	},
	{
		path: 'fileDownload/:itemId/:fileName',
		component: WeblocationFileDownloadComponent,
		canActivate: [AuthGuardService],
	},
	{
		path: 'wLocationDownload/:itemId',
		component: FileDownloadOnViewComponent,
		canActivate: [AuthGuardService],
	},
	{
		path: 'faq',
		component: FaqComponent,
		canActivate: [AuthGuardService],
	},
	{
		path: 'apdm-help',
		component: ApdmHelpComponent,
		canActivate: [AuthGuardService],
	},
	{
		path: 'home',
		component: HomeComponent,
		children: [
			{
				path: 'acc-request',
				component: AccountRequestComponent,
				canActivate: [AuthGuardService],
			},
			{
				path: 'acc-status',
				component: AccStatusComponent,
				canActivate: [AuthGuardService],
			},
			{
				path: 'team-create',
				component: TeamCreateComponent,
				canActivate: [AuthGuardService],
			},
			{
				path: 'work-instruct',
				component: WorkInstructionsComponent,
				canActivate: [AuthGuardService],
			},
			{
				path: 'team-modify',
				component: TeamModifyComponent,
				canActivate: [AuthGuardService],
			},
			{
				path: 'main-page',
				component: MainPageComponent,
				canActivate: [AuthGuardService],
			},
		],
		canActivate: [AuthGuardService],
	},
	{
		path: 'email-download',
		component: EmailDownloadComponent,
		canActivate: [AuthGuardService],
	},
	{
		path: 'email-download/:mail',
		component: EmailDownloadComponent,
		canActivate: [AuthGuardService],
	},
	{
		path: '404',
		component: NotfoundComponent,
	},
	{
		path: '**',
		redirectTo: '/404',
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			useHash: false,
			onSameUrlNavigation: 'reload',
			anchorScrolling: 'enabled',
		}),
	],
	exports: [RouterModule],
	providers: [UrlConsumerService, AuthGuardService],
})
export class AppRoutingModule {}
