import { Component, OnInit } from '@angular/core';
import { ViewPropertiesService } from '../core/service/view-properties.service';
import {ActivatedRoute} from "@angular/router";
import {DomSanitizer} from "@angular/platform-browser";
import {HttpResponse} from "@angular/common/http";

@Component({
  selector: 'view-prop-down-stream-component',
  templateUrl: './view-prop-down-stream-component.component.html',
  styleUrls: ['./view-prop-down-stream-component.component.css']
})
export class ViewPropDownStreamComponentComponent implements OnInit {

	downstreamData: any;
	itemId: any;
	error: any

	constructor(private viewPropertiesServices: ViewPropertiesService, private route: ActivatedRoute, private domSanitizer: DomSanitizer) {
	}

	ngOnInit() {

		this.route.paramMap.subscribe(params => {

			this.itemId = params.get('itemId');

			this.viewPropertiesServices.getDataforDownStream(this.itemId).subscribe(
				(response: HttpResponse<any>) => {
					this.downstreamData = this.domSanitizer.bypassSecurityTrustHtml(response.body);

				},
				(error) => {
					this.error = error;
				}
			);

		});


	}
}
