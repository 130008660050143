import {Component, OnInit} from '@angular/core';
import {ViewPropertiesService} from '../core/service/view-properties.service';
import {ActivatedRoute} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {HttpResponse} from '@angular/common/http';
import {CheckOut} from '../core/model/checkout';

@Component({
	selector: 'view-parent-component',
	templateUrl: './view-parent-component.component.html',
	styleUrls: ['./view-parent-component.component.css']
})
export class ViewParentComponentComponent implements OnInit {
	downstreamData: any;
	data: any;
	error: any;

	constructor(
		private viewPropertiesServices: ViewPropertiesService,
		private route: ActivatedRoute,
		private domSanitizer: DomSanitizer
	) {
	}

	ngOnInit() {
		this.route.paramMap.subscribe(params => {
			this.data = params.get('data');
			console.log('data is :' + this.data);

			this.viewPropertiesServices.getType(this.data).subscribe((checkout: CheckOut) => {
				console.log('type:', checkout.type);
				if (!(checkout.type === '32')) {
					this.viewPropertiesServices.getDataforDownStreamforParent(this.data).subscribe(
						(response: HttpResponse<any>) => {
							this.downstreamData = this.domSanitizer.bypassSecurityTrustHtml(response.body);
							console.log(response.url);
						},
						(error) => {
							this.error = error;
						}
					);
				} else {
					this.viewPropertiesServices.AccessRequiredNew(this.data).subscribe(
						(response: HttpResponse<any>) => {
							console.log('versionDownload', this.data);
							console.log('Resp:' + response);
							const url = response.body.url;
							this.downloadFileFromUrl(url);
							console.log('Data Downloaded Successfully');
						},
						(error) => {
							this.error = error;
							console.log('File Not Downloaded');
						}
					);
				}
			});
		});
	}

	private downloadFileFromUrl(url: string) {
		// Create an anchor element and programmatically click it to trigger the download
		const a = document.createElement('a');
		a.href = url;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		window.close();
	}
}
