import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders } from '@angular/common/http';
import { environment } from "../../../environments/environment";
import { GetTeamRequest, OteamListApi } from '../../core/model/teamModificationRequest';
import { PersonList, TeamList, FormData } from '../../core/model/teamModificationModel';




@Injectable({
  providedIn: 'root'
})
export class TeamModifyService {
  private teamURI = environment.apdmServiceUrl;

  cdsId : any;

	token: any;

	private headers: HttpHeaders;

  constructor(private http: HttpClient) {

    this.token = JSON.parse(sessionStorage.getItem('strAccessToken'));


		if(this.token)
		{
		  this.cdsId = this.token.CommonName;
		}



		//console.log(' teamcreation-cdsid--->', this.cdsId);

		if (this.cdsId !== undefined ) {
		  this.headers = new HttpHeaders().append('Content-Type', 'application/json').append('Access-Control-Allow-Headers', '*');
		} else {
		  this.cdsId = 'Invalid';
		  this.headers = new HttpHeaders().append('Content-Type', 'application/json').append('Access-Control-Allow-Headers', '*');

		}

  }

  getTeamData(teamRequest: GetTeamRequest) {
    return this.http.post(this.teamURI + 'listTeam', teamRequest);
  }


  postTeamModificationRequest(teamselected: OteamListApi) {
    return this.http.post(this.teamURI + 'getTeam', teamselected);
  }

  deletePersonTableRequest(idValue: number, selectedPersonList: PersonList[]) {
    return this.http.post(this.teamURI + 'deletePerson/' + idValue, selectedPersonList);
  }

  deleteTeamTableRequest(idValue: number, selectedteamList: TeamList[]) {
    return this.http.post(this.teamURI + 'deleteTeam/' + idValue, selectedteamList);

  }

  postupdateTeamModifyRequest(formdata: FormData) {
    return this.http.post(this.teamURI + 'updateTeam', formdata);
  }
}
