import { Component, OnInit } from '@angular/core';
import {HttpResponse} from "@angular/common/http";
import {ViewPropertiesService} from "../core/service/view-properties.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Observable} from "rxjs";

@Component({
  selector: 'file-download-on-view',
  templateUrl: './file-download-on-view.component.html',
  styleUrls: ['./file-download-on-view.component.css']
})
export class FileDownloadOnViewComponent implements OnInit {

	itemId:any
	error:any
	data:any

	constructor(private viewPropertiesServices:ViewPropertiesService,private route: ActivatedRoute,private router: Router) { }

	ngOnInit() {
		this.route.paramMap.subscribe(params=>{

			this.itemId=params.get('itemId');

			console.log("itemId is "+this.itemId)

			this.viewPropertiesServices.AccessRequiredNew(this.itemId).subscribe(
				(response:HttpResponse<any>)=>{

					console.log("versionDownload", this.itemId);
                    console.log("Resp:"+response);
					const url =response.body.url;
					this.downloadFileFromUrl(url);
					console.log("Data Doownloaded Sucssesfully")
				},

				(error) => {
					this.error = error;
					console.log("File Not Downloaded")

			});


		});
	}


	private downloadFileFromUrl(url: string) {
		// Create an anchor element and programmatically click it to trigger the download
		const a = document.createElement('a');
		a.href = url;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	}

}
