import {EventEmitter, Injectable, Output} from '@angular/core';
import {Router} from '@angular/router';
import {UserInfo} from '../login/user-info.model';
import {Observable} from 'rxjs/internal/Observable';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Location} from '@angular/common';

@Injectable()
export class UrlConsumerService {
	private teamURI = environment.apdmServiceUrl;
	private headers: HttpHeaders;
	cdsId: any;
	token: any;
	@Output() change: EventEmitter<boolean> = new EventEmitter();
	userInfo: UserInfo = new UserInfo();
	afrcdsid: string = '';
	afryear: string = '';

	constructor(private router: Router, private location: Location, private http: HttpClient) {
		console.log('<---UrlConsumerService constructor--->');
		this.token = JSON.parse(sessionStorage.getItem('strAccessToken'));
		console.log(' UrlConsumerService-Token--->', this.token);

		if (this.token) {
			this.cdsId = this.token.CommonName;
		}

		if (this.cdsId !== undefined) {
			this.headers = new HttpHeaders().append('Content-Type', 'application/json').append('Access-Control-Allow-Headers', '*');
		} else {
			this.cdsId = 'Invalid';
			this.headers = new HttpHeaders().append('Content-Type', 'application/json').append('Access-Control-Allow-Headers', '*');
		}
		this.change.subscribe(() => {
			const token = JSON.parse(sessionStorage.getItem('strAccessToken'));
			console.log('token--->', token);
			this.userInfo.fullName = token.givenname + ' ' + token.surname;
			this.userInfo.cdsId = token.CommonName;
		});
	}

	public getadminaccess(): Observable<any> {
		return this.http.get(this.teamURI + 'hasApplicationAdminAccess', {
			headers: this.headers,
			responseType: 'text'
		});
	}

	public getwebclientaccess(): Observable<string> {
		return this.http.get(this.teamURI + 'baseAction/process', {
			headers: this.headers,
			responseType: 'text'
		});
	}

	toggle() {
		this.change.emit(true);
	}


}
