import { Component, OnInit } from '@angular/core';
import {ViewPropertiesService} from "../core/service/view-properties.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DomSanitizer} from "@angular/platform-browser";
import {HttpResponse} from "@angular/common/http";

@Component({
  selector: 'weblocation-file-download',
  templateUrl: './weblocation-file-download.component.html',
  styleUrls: ['./weblocation-file-download.component.css']
})
export class WeblocationFileDownloadComponent implements OnInit {
	fileName:any
	itemId:any
	error:any
	data:any

	constructor(private viewPropertiesServices:ViewPropertiesService,private route: ActivatedRoute,private router: Router) { }

  ngOnInit() {
	  this.route.paramMap.subscribe(params=>{

		  this.itemId=params.get('itemId');
		  this.fileName=params.get('fileName');
		  console.log("itemId is "+this.itemId+ "&" + "fileName is "+this.fileName)

		  this.viewPropertiesServices.filedownloadWebLocation(this.itemId,this.fileName).subscribe(
			  (response:HttpResponse<Blob>)=>{
				  const blob = response.body;
				  console.log("blob is woking as expected ")
				  if (blob) {
					  const url = window.URL.createObjectURL(blob);  // Create a temporary URL for the Blob
					  const a = document.createElement('a');         // Create a hidden anchor element
					  a.href = url;
					  a.download = this.fileName;                    // Set the download filename
					  a.style.display = 'none';                      // Hide the anchor element
					  document.body.appendChild(a);                   // Add the element to the DOM
					  a.click();                                     // Trigger the download
					  window.URL.revokeObjectURL(url);               // Revoke the temporary URL after download
					  document.body.removeChild(a);                  // Remove the element from the DOM
					  this.router.navigate(['/parent',this.itemId]); // Redirect immediately
					  console.log("Last of if Statement")
				  } else {
					  console.error("Error: Blob is null or undefined.");
				  }

			  });


	  });
  }




}
