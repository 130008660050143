import {Component, OnInit} from '@angular/core';
import {environment} from '../../environments/environment';
import DOMPurify from 'dompurify';


@Component({
	template: `
		<h3>Redirecting to login server</h3>
	`
})
export class LoginComponent implements OnInit {

	_endPoint = environment.adfsUrl +
		'?client_id=' + environment.clientID +
		'&resource=' + environment.resource +
		'&response_type=token' +
		'&redirect_uri=' + encodeURIComponent(environment.redirectUrl.replace(/^http:\/\//i, 'https://'));

	constructor() {
	}

	ngOnInit() {
		console.log('document.URL ==> ' + document.URL);
		let sanitizedUrl = '';
		if (document.URL.startsWith('http:')) {
			const httpsUrl = document.URL.substring(0, document.URL.indexOf('#') - 1).replace(/^http:\/\//i, 'https://');
			console.log('http changes = ' + httpsUrl);
			sanitizedUrl = DOMPurify.sanitize(httpsUrl);

		} else {
			console.log('<---LoginComponent OnInit--->');
			sanitizedUrl = DOMPurify.sanitize(this._endPoint);

		}
		if (this.isValidHttpsUrl(sanitizedUrl)) {
			window.location.assign(sanitizedUrl); // Use href as required
		} else {
			console.error('Invalid or unsafe URL:', sanitizedUrl);
			// Handle the error, such as displaying a message or logging the issue
		}

	}

	private isValidHttpsUrl(url: string): boolean {
		try {
			const parsedUrl = new URL(url);
			return parsedUrl.protocol === 'https:'; // Ensure the URL uses HTTPS
		} catch (e) {
			return false;
		}
	}
}