import { NgModule } from "@angular/core";
import {
	CommonModule,
	Location,
	LocationStrategy,
	PathLocationStrategy,
} from "@angular/common";
import { AuthGuardService } from "./auth-guard.service";
import { UrlConsumerService } from "./url-consumer.service";
import { LoginComponent } from "./login.component";
import { OAuthCallbackComponent } from "./oauth-callback.component";

@NgModule({
	imports: [CommonModule],
	declarations: [LoginComponent, OAuthCallbackComponent],
	providers: [
		AuthGuardService,
		UrlConsumerService,
		Location,
		{ provide: LocationStrategy, useClass: PathLocationStrategy },
	],
})
export class OauthModule {}
