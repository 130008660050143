import {BrowserModule, Title} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppComponent} from './app.component';
import {NavComponent} from './nav/nav.component';
import {GrowlModule, MenubarModule, SidebarModule} from 'primeng/primeng';
import {MessageService} from 'primeng/components/common/messageservice';
import {AppRoutingModule} from './app-routing.module';
import {SplitButtonModule} from 'primeng/splitbutton';
import {PanelMenuModule} from 'primeng/panelmenu';
import {HomeModule} from './home/home.module';
import {HelpModule} from './help/help.module';
import {ErrorPageModule} from './error-page/error-page.module';
import {SharedModule} from './shared/shared.module';
import {TeamcreationService} from './core/service/team-creation.service';
import {DialogModule} from 'primeng/dialog';
import {AccountStatusService} from './core/service/account-status.service';
import {DatePipe, Location} from '@angular/common';
import {OauthModule} from './oauth/oauth.module';
import {HttpRequestInterceptor} from '../services/http-request-interceptor';
import {AdminModule} from './admin/admin.module';
import {UploadModule} from './upload-from-url/upload.module';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ViewParentComponentComponent} from './view-parent-component/view-parent-component.component';
import {
	ViewPropDownStreamComponentComponent
} from './view-prop-down-stream-component/view-prop-down-stream-component.component';
import {WebLocationComponent} from './web-location/web-location.component';
import {WeblocationFileDownloadComponent} from './weblocation-file-download/weblocation-file-download.component';
import {FileDownloadOnViewComponent} from './file-download-on-view/file-download-on-view.component';
import {UrlConsumerService} from './oauth/url-consumer.service';
import { NotfoundComponent } from './notfound/notfound.component';

@NgModule({
	declarations: [
		AppComponent,
		NavComponent,
		ViewParentComponentComponent,
		ViewPropDownStreamComponentComponent,
		WebLocationComponent,
		WeblocationFileDownloadComponent,
		FileDownloadOnViewComponent,
		NotfoundComponent,
		//	WebdavComponent,
		//	WebdavtableComponent,
	],

	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		RouterModule,
		AppRoutingModule,
		MenubarModule,
		GrowlModule,
		SidebarModule,
		SplitButtonModule,
		PanelMenuModule,
		HomeModule,
		HelpModule,
		ErrorPageModule,
		SharedModule,
		//	WebclientmoduleModule,
		DialogModule,
		AdminModule,
		OauthModule,
		UploadModule
		//	LoginModule
	],
	entryComponents: [
		AppComponent,
		//	WebdavComponent
	],
	exports: [
		AppComponent,
		NavComponent,
		//	WebdavComponent,
	],
	providers: [MessageService, Location, UrlConsumerService, TeamcreationService, AccountStatusService, Title, DatePipe, {
		provide: HTTP_INTERCEPTORS,
		useClass: HttpRequestInterceptor,
		multi: true
	},
// 	{ provide: APP_BASE_HREF, useValue: '/webdav' },
// 	{ provide: APP_BASE_HREF, useValue: '/webclient' }
	],
	//bootstrap: [AppComponent]
})
export class AppModule {


	ngDoBootstrap(app) {

		fetch('url/to/fetch/component/name')
			.then((name) => {
				bootstrapRootComponent(app, name);
			});
	}
}


// app - reference to the running application (ApplicationRef)
// name - name (selector) of the component to bootstrap
function bootstrapRootComponent(app, name) {
	// define the possible bootstrap components
	// with their selectors (html host elements)
	const options = {
		'app-root': AppComponent,
		//'webdav': WebdavComponent
	};

	const componentElement = document.createElement(name);
	document.body.appendChild(componentElement);
	// bootstrap the application with the selected component
	const component = options[name];
	app.bootstrap(component);
}


function fetch(url) {
	//	console.log("environment", environment.apdmwebdav);

	console.log(window.location.href);
	console.log('routeurl', window.location.href);
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve('app-root');
		}, 100);
	});
}
